import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  Get,
  GooglePlaces,
  Post,
} from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import DateField from "../../Components/Date/DateField";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Email from "../../Components/Email/Email";
import SSN from "../../Components/SSN/SSN";
import Text from "../../Components/Text/Text";
import States from "../../Components/UsStates/States";
import { useStateValue } from "../../Context/StateProvider";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import AddressAutocomplete from "../../Components/Address/Address";
import Metamap from "../../Components/Onboarding-Services/Metamap";
import { idologyPassResponse } from "../../sampleResponses/idology";
import { v4 as uuidv4 } from "uuid";
import TextArea from "../../Components/TextArea/TextArea";
import ShuftiPro from "../../Components/Onboarding-Services/ShuftiPro";
import { PersonaInt } from "../../Components/Onboarding-Services/Persona";
import { hasNullValueInObject }  from '../../common_var/util';

const PersonalInformation = ({ step }) => {
  const location = useLocation();
  const token = location.pathname?.toString().split("/")[3];
  const [
    {
      errors,
      formData,
      globalStep,
      currentStep,
      cosignerCheckbox,
      onboardConditions,
      integrationsStatus,
      frames,
      // step,
      isCosignorFlow,
      isFullPreview,
      requiredFields,
    },
    dispatch,
  ] = useStateValue();
  const [value, setValue] = useState(
    formData?.street_address !== undefined ? formData?.street_address : null
    );
  const { loanid } = useParams();
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [SSNVal, setSSNVal] = useState("");
  const userId = sessionStorage.getItem("user_id");
  const [preventVerification, setPreventVerification] = useState(false);

  const getDetails = async () => {
    try {
      // let resp = await Get(`loan/fetch-user/${userId}`, "admin");
      // setUserDetails(resp.data[0]);
      let res = await AuthGet(
        `loan/fetch-personalinfo/${loanid}`,
        "onboarding"
      );
      if (res.data) {
        setPreventVerification(true);
      } else {
        setPreventVerification(false);
      }
      setSSNVal(res?.data?.length > 0 && res?.data[0]?.ssn);
      dispatch({type: "CLEAR_VALUES"});
      dispatch({
        type: "SET_VALUES",
        payload: res?.data?.length > 0 && res.data[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({type: "RESET_REQUIRED_FIELDS"});
    dispatch({
      type: "CLEAR_ERRORS",
    });
    if (globalStep <= currentStep) {
      dispatch({
        type: "SET_ERRORS",
        payload: { personalCheckbox: "not checked" },
      });
    }
    getDetails();
  }, [formData.agree]);

  useEffect(() => {
    if (formData?.street_address !== undefined) {
      setValue(formData?.street_address);
    }
  }, [formData]);

  const handleChange = (e) => {
    setPreventVerification(false); //TO VERIFY AGAIN IF THE FORM IS EDITED AGAIN AFTER SUBMISSION
    const name = e?.target?.name;
    let val = e?.target?.value;

    if (name == "ssn") {
      if (val.length > 9) {
        setSSNVal("");
        val = "";
      } else {
        setSSNVal(val);
      }
    }

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const mainConnection = () => {
    const newIntegrationsStatus = { ...integrationsStatus };
    const service =
      onboardConditions?.personal_info?.services?.length > 0
        ? onboardConditions?.personal_info?.services[0]
        : "";
    switch (
      service /* CURRENTLY NOT CONSIDERING MULTIPLE IDENTITY VERIFICATION SYSTEM */
    ) {
      case "Instnt":
        break;
      case "Metamap":
        newIntegrationsStatus.identityVerification["metamap"].activate = true;
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newIntegrationsStatus },
        });

        setTimeout(() => {
          let element = document.getElementById("mati-button");
          element.click();
        }, 1000);

        break;
      case "Shufti Pro":
        newIntegrationsStatus.identityVerification["shuftipro"].activate = true;
        console.log(newIntegrationsStatus);
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newIntegrationsStatus },
        });

        break;
      case "Idology":
        idologyPassResponse.borowerIdology.response["id-number"] = uuidv4();
        const body = {
          // user_id: userId,
          loan_id: loanid,
          service: "Idology",
          frame: "personal_info",
          verification: "Identity Verification",
          response: JSON.stringify(idologyPassResponse),
          status: true,
        };
        try {
          Post("api/save-service", body, "admin");
          save();
        } catch (err) {
          body.status = false;
          Post("api/save-service", body, "admin");
          console.log("err::: ", err);
        }
        break;
      case "Persona":
        newIntegrationsStatus.identityVerification["persona"].activate = true;
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newIntegrationsStatus },
        });

        break;
      default:
        save();
        break;
    }
  };

  const save = () => {
    formData["ssn"] = SSNVal;
    const bodyData = {
      ...formData,
      loan_id: loanid,
      isCosignor: cosignerCheckbox,
      step: step,
      // email: userDetails.email,
      // phone: userDetails.mobile,
    };

    AuthPost("loan/update-personal-info", bodyData, "onboarding").then(
      (resp) => {
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({type: "RESET_REQUIRED_FIELDS"});
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
              ? resp.data[0].active_cosignor_step
              : resp.data[0].step,
          });

          const newIntegrationsStatus = { ...integrationsStatus };
          newIntegrationsStatus.identityVerification.persona.activate = false;
          newIntegrationsStatus.identityVerification.shuftipro.activate = false;
          dispatch({
            type: "CHANGE_SERVICE_STATUS",
            payload: { ...newIntegrationsStatus },
          });

          const nextFrame = frames.filter((el) => el.step === currentStep + 1);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
          // localStorage.setItem("userid",user_id);
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errors.personalCheckbox) {
      setAgreeCheckBox(true);
      toast.error("Select Checkbox");
    } else {
      setAgreeCheckBox(false);
    }
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });

    const isFilled = hasNullValueInObject({...formData }, requiredFields);
    if(!isFilled) {
      console.log('isFilled::: ', isFilled);
      return;
    }

    if (Object.keys(errors).length === 0) {
      try {
        if (preventVerification) {
          save();
          return;
        }

        mainConnection();
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const handleCheckBox = (val) => {
    if (val === true) {
      setAgreeCheckBox(false);
      dispatch({
        type: "REMOVE_ERROR",
        payload: "personalCheckbox",
      });
    } else if (val === false) {
      setAgreeCheckBox(true);
      dispatch({
        type: "SET_ERRORS",
        payload: { personalCheckbox: "not checked" },
      });
    }
  };

  const today = new Date();
  let maxDate = new Date(
    today.getFullYear() + 30,
    today.getMonth(),
    today.getDate()
  );
  let minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );

  const handleAddress = async (e) => {
    setValue(e?.value?.description);
    let Address_data = await GooglePlaces(e);
    dispatch({
      type: "SET_VALUES",
      payload: {
        street_address: Address_data.Address,
        zipcode: Address_data.Zipcode,
        state: Address_data.State,
        city: Address_data.City,
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* <TextArea
            name={"comments"}
            labelName={"Comments"}
            // required={true}
            handleChange={handleChange}
            placeholder="Some message..."
          /> */}
          <Text
            name={"firstname"}
            labelName={"First Name"}
            placeholder={"First Name"}
            handleChange={handleChange}
            required={true}
            // trim={true}
            readonly={isFullPreview ? true : false}
          />
          <Text
            name={"middle_initial"}
            labelName={"Middle Initials"}
            placeholder={"Middle Initials"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            // trim={true}
          />
          <Text
            name={"lastname"}
            labelName={"Last Name"}
            placeholder={"Last Name"}
            handleChange={handleChange}
            required={true}
            readonly={isFullPreview ? true : false}
            // trim={true}
          />
          <DateField
            minYear={minDate.getFullYear()}
            minDate={minDate}
            maxDate={today}
            name={"date_of_birth"}
            labelName={"Date of Birth"}
            placeholder="Date of Birth (MM-DD-YYYY)"
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <SSN
            name={"ssn"}
            placeholder={"Social Security Number"}
            labelName={"Social Security Number "}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={false}
          />
          <AddressAutocomplete
            handleAddress={handleAddress}
            value={value}
            required={true}
            labelName={"Address"}
            readonly={isFullPreview ? false : true}
            name={"street_address"}
          />
          <Text
            name={"unit"}
            labelName={"Unit"}
            placeholder={"Example: 10B"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Text
            name={"city"}
            labelName={"City"}
            placeholder={"Example: Alaska"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Text
            name={"state"}
            placeholder={"Example: IOWA"}
            labelName={"State"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Text
            // maxLen={6}
            name={"zipcode"}
            labelName={"Zipcode"}
            placeholder={"Example: 12345"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Dropdown
            data={[
              { key: "married", value: "Married" },
              { key: "unmarried", value: "Unmarried" },
            ]}
            name={"marital_status"}
            labelName={"Marital Status"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Dropdown
            data={[
              { key: "resident", value: "Resident" },
              { key: "non-resident", value: "Non-Resident" },
              { key: "citizen", value: "Citizen" },
            ]}
            name={"usresidency_status"}
            labelName={"US Residency Status"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Dropdown
            data={[
              { key: "english", value: "English" },
              { key: "spanish", value: "Spanish" },
            ]}
            name={"spoken_language"}
            labelName={"Spoken Language"}
            handleChange={handleChange}
            readonly={isFullPreview ? true : false}
            required={true}
          />
          <Email
            name={"sales_rep_email"}
            labelName={"Sales Rep Email"}
            placeholder={"Sales Rep Email"}
            readonly={isFullPreview ? true : false}
            handleChange={handleChange}
          />
          {!isFullPreview && (
            <div
              className={`customCheckbox mb_24 ${
                agreeCheckBox ? "errorCheckbox" : ""
              }`}
            >
              <input
                defaultChecked={globalStep > currentStep ? true : false}
                type="checkbox"
                name="agree"
                id="PersonalCheck"
                onChange={(e) => handleCheckBox(e.target.checked)}
              />
              <label htmlFor="PersonalCheck">
                I consent to the{" "}
                <a
                  href={`${process.env.REACT_APP_ADMIN_API_URL}/files/save/${loanid}/othermessaging_v1.pdf`}
                  style={{ color: "blue" }}
                  target={"_blank"}
                >
                  SMS Policy
                </a>
                ,&nbsp;
                <a
                  href={`${process.env.REACT_APP_ADMIN_API_URL}/files/save/${loanid}/creditpull.pdf`}
                  style={{ color: "blue" }}
                  target={"_blank"}
                >
                  Credit Pull Consent
                </a>
                ,&nbsp;
                <a
                  href={`${process.env.REACT_APP_ADMIN_API_URL}/files/save/${loanid}/esignature.pdf`}
                  style={{ color: "blue" }}
                  target={"_blank"}
                >
                  E-signature agreements
                </a>{" "}
                and{" "}
                <a
                  href={`${process.env.REACT_APP_ADMIN_API_URL}/files/save/${loanid}/electronic.pdf`}
                  style={{ color: "blue" }}
                  target={"_blank"}
                >
                  Electronic Funds Transfer Authorization
                </a>
              </label>
            </div>
          )}
          {!isFullPreview && (
            <div className="displayFlex">
              <Button
                type={"submit"}
                value={"Continue"}
                handleClick={handleSubmit}
              />
            </div>
          )}
        </div>
      </form>

      {integrationsStatus.identityVerification["metamap"].activate ? (
        <Metamap loanid={loanid} userId={userId} save={save} />
      ) : integrationsStatus.identityVerification["persona"].activate ? (
        <PersonaInt save={save} />
      ) : integrationsStatus.identityVerification["shuftipro"].activate ? (
        <ShuftiPro save={save} />
      ) : null}
    </>
  );
};

export default PersonalInformation;
